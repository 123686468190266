import React, { PropsWithChildren, useEffect, useState } from 'react'
import { MessagesLoaders, getApolloClient } from './utils'

import { ApolloProvider } from '@apollo/client'
import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types'
import { styled } from '@mui/material/styles'
import { BrowserClientOptions } from '@sentry/browser/types/client'
import * as Env from './Env'
import { BackgroundVariants } from './enums/backgroundVariants'
import { useThriveApplicationTheme } from './hooks'
import useHybridFunctions from './hooks/useHybridFunctions'
import { isRunningInWebview } from './mobile-communication'
import store, { userSetDDSessionId } from './store'
import { InternationalizedApplication } from './utils/Intl/InternationalizedApplication'
import langLoader from './utils/Intl/langLoader'

interface ThriveApplicationProps {
    /** @deprecated use sentryOptions.dsn which overrides sentryDsn if set */
    sentryDsn?: string
    commitHash?: string
    sentryOptions?: Partial<BrowserClientOptions> & Required<{ dsn: string }>
    apolloClientOptions?: {
        name: string
        cacheOptions: InMemoryCacheConfig
    }
    /** @deprecated We only have one configCat sdk now. This will not do anything */
    configCatSdk?: string
    backgroundVariant?: BackgroundVariants
    disableMaxWidth?: boolean
    sentryUnauthenticatedUserId?: string
    messagesLoaders?: MessagesLoaders
}

interface ContentFullBleedProps {
    backgroundvariant: string
    isrunninginwebview: boolean
}
//handles maximum content width
const ThriveApplicationMaxWidth = styled('div')(({ theme }) => ({
    minHeight: 'inherit',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
        maxWidth: 'var(--content-max-width)',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}))

//for new nav, handles background color
const ThriveApplicationNavResizeWrapper = styled(
    'section'
)<ContentFullBleedProps>(
    ({ backgroundvariant, isrunninginwebview, theme }) => ({
        minHeight: 'calc(100vh - var(--navbar-height))',
        background: `${backgroundvariant} !important`,
        backgroundImage: `${backgroundvariant} !important`,
        backgroundAttachment: 'fixed',
        [theme.breakpoints.down('md')]: {
            minHeight: isrunninginwebview
                ? 'calc(100vh - var(--navbar-height-small))'
                : 'calc(100vh - var(--navbar-height-small-native))'
        }
    })
)

export const ThriveApplication: React.FC<
    PropsWithChildren<ThriveApplicationProps>
> = ({
    children,
    sentryDsn,
    commitHash,
    apolloClientOptions,
    backgroundVariant,
    disableMaxWidth = false,
    sentryOptions,
    sentryUnauthenticatedUserId,
    messagesLoaders = []
}) => {
    // Optional since we can mount multiple apps at once and may not want sentry for everything (Eg. Dashboard)
    useHybridFunctions()
    const theme = useThriveApplicationTheme()

    useEffect(() => {
        window?.DD_RUM?.onReady(() => {
            store.dispatch(userSetDDSessionId())
        })
    }, [])

    const noNavBarScreens = ['thrive-web-dashboard', 'thrive-web-sign-in']

    const [backgroundColor, setBackgroundColor] = useState<string>('')

    const primaryProductPaths = [
        'today',
        'insights',
        'challenges',
        'library',
        'learn',
        'reset',
        'pulse'
    ]

    useEffect(() => {
        const handleBackgroundChange = () => {
            const pathname = location?.pathname
            const partitionedPathname = pathname
                .split('/')
                .filter((pathElem) => pathElem !== '')

            if (backgroundVariant) {
                //if custom background Variant is to be set
                switch (backgroundVariant as BackgroundVariants) {
                    case BackgroundVariants.DEFAULT: {
                        setBackgroundColor(theme.palette.background.default)
                        break
                    }
                    case BackgroundVariants.PAPER: {
                        setBackgroundColor(theme.palette.background.paper)
                        break
                    }
                    case BackgroundVariants.GRADIENT_MAIN: {
                        setBackgroundColor(theme.gradient.main)
                        break
                    }
                    case BackgroundVariants.GRADIENT_LIGHT: {
                        setBackgroundColor(theme.gradient.light)
                        break
                    }
                    default: {
                        break
                    }
                }
            } else {
                if (
                    partitionedPathname[0] === 'journeys' ||
                    (primaryProductPaths.includes(partitionedPathname[0]) &&
                        partitionedPathname.length === 1)
                ) {
                    setBackgroundColor(theme?.gradient.light) //if on root page of main product pages or on any journeys page
                } else {
                    setBackgroundColor(theme?.palette.background.paper)
                }
            }
        }

        if (apolloClientOptions?.name !== 'thrive-web-dashboard') {
            handleBackgroundChange()
            // Add the listener for popstate
            window.addEventListener('popstate', handleBackgroundChange)
            // On unmount of this component cleanup and remove the listener
            return () =>
                window.removeEventListener('popstate', handleBackgroundChange)
        }
    }, [apolloClientOptions?.name])

    if (apolloClientOptions) {
        const apolloClient = getApolloClient(
            apolloClientOptions.cacheOptions,
            apolloClientOptions.name
        )
        return (
            <InternationalizedApplication
                messagesLoaders={[
                    langLoader(Env.cloudflareCoreURL),
                    langLoader(Env.cloudflareLeafkitURL),
                    langLoader(Env.cloudflareChallengesURL),
                    langLoader(Env.cloudflareAchievementsURL),
                    langLoader(Env.cloudflareWearablesCoreURL),
                    langLoader(Env.cloudflarePulseInsightsURL),
                    langLoader(Env.cloudflareTodayURL),
                    langLoader(Env.cloudflareCentralizedNotificationsURL),
                    ...messagesLoaders
                ]}
            >
                <ApolloProvider client={apolloClient}>
                    {noNavBarScreens.includes(apolloClientOptions.name) ? (
                        children
                    ) : (
                        <>
                            <ThriveApplicationNavResizeWrapper
                                id="thrive-application-main-content"
                                backgroundvariant={backgroundColor}
                                isrunninginwebview={isRunningInWebview()}
                            >
                                {disableMaxWidth ? (
                                    children
                                ) : (
                                    <ThriveApplicationMaxWidth>
                                        {children}
                                    </ThriveApplicationMaxWidth>
                                )}
                            </ThriveApplicationNavResizeWrapper>
                        </>
                    )}
                </ApolloProvider>
            </InternationalizedApplication>
        )
    }
    return <>{children}</>
}
