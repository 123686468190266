import { useCallback, useMemo } from 'react'
import { useIsFeatureEnabled } from '../../utils'

export const useGenerateTeamsDeepLink = () => {
    const cloudDomainEnabled = useIsFeatureEnabled(
        'msteamsCloudDomain',
        false,
        true
    )

    const getUrl = useCallback(() => {
        const teamsUrl = cloudDomainEnabled
            ? process.env.MSTEAMS_CLOUD_APP_URL
            : process.env.MSTEAMS_APP_URL
        const appId = process.env.MSTEAMS_APP_ID

        return new URL(`${teamsUrl}/l/entity/${appId}/home`)
    }, [cloudDomainEnabled])

    return useCallback(
        (path?: string) => {
            const url = getUrl()

            if (path) {
                url.searchParams.append(
                    'context',
                    JSON.stringify({ subEntityId: path })
                )
            }

            return url
        },
        [getUrl]
    )
}
