import { captureException } from '../Sentry'

export const enum ReferralType {
    Default = 'default',
    Challenge = 'challenge'
}
export interface ReferralEmailRequestBody {
    userId: string
    emailAddresses: string[]
    coworkerReferralLink: string
    friendsAndFamilyReferralLink: string
    clientId: string
    companyId: string
    allowExistingUsers?: boolean
    referralType?: ReferralType
}

export const enum SendReferralEmailResultType {
    Success = 'success',
    Error = 'error'
}

export interface SendReferralEmailResponseData {
    alreadyUsers: string[]
    failedToSendEmailTo: string[]
}

export interface SendReferralEmailResult {
    status: SendReferralEmailResultType
    error?: string
    data?: SendReferralEmailResponseData
}

export const sendReferralEmail = async (
    body: ReferralEmailRequestBody
): Promise<SendReferralEmailResult> => {
    try {
        const resp = await fetch(process.env.REFERRAL_EMAIL_URL, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                accept: '*/*'
            },
            body: JSON.stringify({ ...body })
        })
        if (!resp.ok) {
            const error = new Error('Error sending referral email')
            captureException(
                error,
                { message: `Status: ${resp.status}` },
                'identity'
            )
            return {
                status: SendReferralEmailResultType.Error,
                error: error.message
            }
        }
        const data = await resp.json()
        return {
            status: SendReferralEmailResultType.Success,
            data
        }
    } catch (error) {
        return {
            status: SendReferralEmailResultType.Error,
            error: error.message
        }
    }
}
